<template>
    <div class="basic-modal">
        <div class="row modal-header">
            <div class="col d-flex align-items-center">
                <h5>Mark Lead as Lost</h5>
                <a class="ml-auto" href="#" @click="$modal.hide('lost-lead-modal')">x</a>
            </div>
        </div>
        <div class="modal-body">
            <form class="" @submit.prevent.stop="save()">
                <div class="row">
                    <!-- reason -->
                    <div class="form-group col-md-12">
                        <div class="control">
                            <label for="middleName"> Reason </label>
                            <input
                                v-model="formData.reason_lost"
                                v-validate="'required'"
                                type="text"
                                class="form-control"
                                placeholder="Reason"
                                name="reason"
                            >
                            <span class="text-danger">{{ errors.first("reason") }}</span>
                        </div>
                    </div>
                    <!-- reason -->

                    <!-- comments -->
                    <div class="form-group col-md-12">
                        <div class="control">
                            <label for="middleName"> Comments </label>
                            <textarea
                                v-model="formData.comments"
                                type="text"
                                class="form-control"
                                placeholder="Doe"
                                name="comments"
                            />
                            <span class="text-danger">{{ errors.first("comments") }}</span>
                        </div>
                    </div>
                    <!-- End of comments -->
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger btn-normal" @click="$modal.hide('lost-lead-modal')">
                Cancel
            </button>
            <button class="btn btn-primary btn-normal" :disabled="isLoading" @click="save()">
                Mark as Lost
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                type: Object,
                default() {
                    return {}
                }
            },
            isLoading: false
        }
    },
    methods: {
        async save() {
            const isValid = await this.$validator.validateAll()
            if (!isValid) {
                this.focusFirstError(false)
                return;
            }

            this.formData.status = 2;

            this.$emit("saved", this.formData);
            this.$modal.hide("lost-lead-modal");
            this.formData = {}
        }

    }
}
</script>

<style>

</style>
